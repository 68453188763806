import React, { PropsWithChildren, ReactElement } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

/** Interfaces */

interface IListItemProps {
    icon?: ReactElement;
    action?: ReactElement;
    bottom_section?: ReactElement | null;
}

/** Styles */
const StyledListItemContainer = styled.div<{ autoHeight: boolean }>`
    display: flex;
    flex: 0 0 100%;
    height: 84px;
    ${(props): FlattenSimpleInterpolation | false =>
        props.autoHeight &&
        css`
            margin-top: 10px;
        `}
`;

const StyledListItemContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 16px;
`;

const StyledIconWrapper = styled.div<{ autoHeight: boolean }>`
    display: flex;
    height: auto;
    align-items: center;
    margin-right: 16px;

    ${(props): FlattenSimpleInterpolation | false =>
        props.autoHeight &&
        css`
            align-items: flex-start;
            margin-top: 5px;
        `}
`;
const StyledActionWrapper = styled.div`
    display: flex;
    height: auto;
    align-items: center;
`;

const StyledRightSection = styled.div<{ autoHeight: boolean }>`
    display: flex;
    justify-content: space-between;
    height: 96px;
    ${(props): FlattenSimpleInterpolation | false =>
        props.autoHeight &&
        css`
            height: auto;
        `}
`;

export const StyledDividerWrapper = styled.div<{ show_gap?: boolean }>`
    display: flex;
    flex: 1;
    flex-direction: column;
    row-gap: ${(props): string => (props.show_gap ? '10px' : '0')};
`;

const StyledBottomSection = styled.div`
    display: flex;
`;

/** Components */
export default function ListItem(props: PropsWithChildren<IListItemProps>): ReactElement {
    const { action, icon, children, bottom_section } = props;
    const auto_height = !!bottom_section;

    return (
        <StyledListItemContainer autoHeight={auto_height}>
            {icon ? <StyledIconWrapper autoHeight={auto_height}>{icon}</StyledIconWrapper> : null}

            <StyledDividerWrapper className={'list-divider-section'}>
                <StyledRightSection autoHeight={auto_height}>
                    <StyledListItemContent>{children}</StyledListItemContent>
                    {action ? <StyledActionWrapper>{action}</StyledActionWrapper> : null}
                </StyledRightSection>
                {bottom_section ? (
                    <StyledBottomSection>{bottom_section}</StyledBottomSection>
                ) : null}
            </StyledDividerWrapper>
        </StyledListItemContainer>
    );
}
