import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { HeaderProps } from './CardHeader';

// Styled components

export const StyledHeader = styled.div<Pick<HeaderProps, 'layout'>>`
    display: flex;

    /**
     * These CSS are controlled by layout props.
     * Row layout has two columns with right column for action component.
     * This is utilitized by CardHeaderWithRoadblock to display upgrade button on the right.
     */
    flex-direction: ${(props): string => props.layout || 'column'};
    gap: ${(props): string => (props.layout === 'row' ? '23px' : '0')};
    align-items: ${(props): string => (props.layout === 'row' ? 'center' : 'normal')};
    justify-content: ${(props): string => (props.layout === 'row' ? 'space-between' : 'normal')};

    /**
     * Row layout will become column on tablet and mobile to ensure action component has enough room.
     */
    ${(props): FlattenSimpleInterpolation | string => {
        const tablet_breakpoint = props.theme.responsiveBreakPoints.tablet;

        if (props.layout !== 'row') {
            return '';
        }

        return css`
            @media screen and (max-width: ${tablet_breakpoint}px) {
                flex-direction: column;
            }
        `;
    }}

    color: ${(props): string => props.theme.colors.black};
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 9px;
`;

export const StyledTitle = styled.div<Pick<HeaderProps, 'size'>>`
    color: ${(props): string => props.theme.colors.black};
    font-weight: 600;
    font-size: ${(props): string => titleFontSize(props.size)};
    padding-bottom: ${(props): string => titlePaddingBottom(props.size)};
    line-height: ${(props): string => titleLineHeight(props.size)};
`;

export const StyledDescription = styled.div<Pick<HeaderProps, 'description' | 'size'>>`
    color: ${(props): string => props.theme.colors.grey600};
    font-size: ${(props): string => descriptionFontSize(props.size)};
    line-height: 20px;
    padding-bottom: ${(props): string =>
        descriptionPaddingBottom(props.size as 'medium' | 'large')};
`;

export const StyledActionComponent = styled.div<Pick<HeaderProps, 'size'>>`
    padding-bottom: ${(props): string => titlePaddingBottom(props.size)};
`;

// Helpers
function titleFontSize(size: 'small' | 'medium' | 'large'): string {
    switch (size) {
        case 'small':
            return '12px';
        case 'medium':
            return '16px';
        case 'large':
            return '24px';
        default:
            return '16px';
    }
}

function descriptionFontSize(size: 'small' | 'medium' | 'large'): string {
    switch (size) {
        case 'large':
            return '16px';
        default:
            return '14px';
    }
}

function titlePaddingBottom(size: 'small' | 'medium' | 'large'): string {
    switch (size) {
        case 'small':
            return '8px';
        default:
            return '16px';
    }
}

function titleLineHeight(size: 'small' | 'medium' | 'large'): string {
    switch (size) {
        case 'small':
            return '16px';
        case 'medium':
            return '24px';
        case 'large':
            return '32px';
        default:
            return '24px';
    }
}

function descriptionPaddingBottom(size: 'medium' | 'large'): string {
    switch (size) {
        case 'medium':
            return '16px';
        case 'large':
            return '16px';
        default:
            return '16px';
    }
}
