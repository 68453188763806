import { translate } from '@nicejob-library/internationalization';
import { IValidationRules } from './Validation';

export const recommendation_settings_validation_rules: IValidationRules = {
    'recommendation_settings.header_text': {
        label: translate({
            namespace: 'settings',
            key: 'product.recommendations.header_label',
        }),
        required: true,
    },
    'recommendation_settings.subheader_text': {
        label: translate({
            namespace: 'settings',
            key: 'product.recommendations.subheader_label',
        }),
        required: false,
    },
    'recommendation_settings.landing_page_url': {
        type: 'url',
        label: translate({
            namespace: 'settings',
            key: 'product.recommendations.landing_page_url_label',
        }),
        required: true,
    },
    'recommendation_settings.message_template_id': {
        label: 'Message Template',
        required: true,
    },
    'recommendation_settings.share_link': {
        label: 'Share Link',
        required: false,
        type: 'url',
    },
};
