import React, { forwardRef, ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

export interface ILinkButtonProps {
    onClick: () => void;
    children: ReactNode;
    text_color?: string;
    className?: string;
}

interface IStyledLinkButtonProps {
    text_color?: string;
}

export const StyledLinkButton = styled.a<IStyledLinkButtonProps>`
    color: ${(props): string => (props.text_color ? props.text_color : props.theme.colors.blue600)};
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

export const LinkButton = forwardRef<HTMLAnchorElement, ILinkButtonProps>(function(
    props,
    ref
): ReactElement {
    const { children, onClick, text_color, className } = props;

    return (
        <StyledLinkButton ref={ref} className={className} onClick={onClick} text_color={text_color}>
            {children}
        </StyledLinkButton>
    );
});
