import React, { PropsWithChildren, ReactElement } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { generateCSSDeclaration } from './helpers';

export type CSSUnitValue = number | string;

export interface IBoxBreakPoints<T> {
    mobile?: T;
    tablet?: T;
    desktop?: T;
}

export enum CSS_PROPERTIES {
    // global properties
    display = 'display',
    height = 'height',
    width = 'width',
    backgroundColor = 'backgroundColor',
    position = 'position',

    // spacing properties
    m = 'm',
    mx = 'mx',
    my = 'my',
    mt = 'mt',
    ml = 'ml',
    mr = 'mr',
    mb = 'mb',
    p = 'p',
    px = 'px',
    py = 'py',
    pt = 'pt',
    pl = 'pl',
    pr = 'pr',
    pb = 'pb',
    // Flex and Grid properties
    flex = 'flex',
    flexDirection = 'flexDirection',
    flexWrap = 'flexWrap',
    flexFlow = 'flexFlow',
    flexGrow = 'flexGrow',
    flexShrink = 'flexShrink',
    flexBasis = 'flexBasis',

    grid = 'grid',
    gridTemplateColumns = 'gridTemplateColumns',
    gridTemplateRows = 'gridTemplateRows',
    gridAutoRows = 'gridAutoRows',
    gridAutoColumns = 'gridAutoColumns',
    gridAutoFlow = 'gridAutoFlow',
    gridTemplateAreas = 'gridTemplateAreas',
    gridTemplate = 'gridTemplate',
    gridArea = 'gridArea',
    gridRow = 'gridRow',
    gridColumn = 'gridColumn',
    gridColumnStart = 'gridColumnStart',
    gridColumnEnd = 'gridColumnEnd',
    gridRowStart = 'gridRowStart',
    gridRowEnd = 'gridRowEnd',

    gap = 'gap',
    rowGap = 'rowGap',
    columnGap = 'columnGap',
    alignItems = 'alignItems',
    alignContent = 'alignContent',
    alignSelf = 'alignSelf',
    justifyItems = 'justifyItems',
    justifyContent = 'justifyContent',
    justifySelf = 'justifySelf',
    placeItems = 'placeItems',
    placeContent = 'placeContent',
    placeSelf = 'placeSelf',
}

type SXProperty<Type> = Type | IBoxBreakPoints<Type>;

interface Isx {
    // Display property
    [CSS_PROPERTIES.display]: SXProperty<CSSProperties['display']>;
    [CSS_PROPERTIES.height]: SXProperty<CSSProperties['height']>;
    [CSS_PROPERTIES.width]: SXProperty<CSSProperties['width']>;
    [CSS_PROPERTIES.backgroundColor]: SXProperty<CSSProperties['backgroundColor']>;
    [CSS_PROPERTIES.position]: SXProperty<CSSProperties['position']>;

    // Spacing properties
    [CSS_PROPERTIES.m]: SXProperty<CSSUnitValue>;
    [CSS_PROPERTIES.mx]: SXProperty<CSSUnitValue>;
    [CSS_PROPERTIES.my]: SXProperty<CSSUnitValue>;
    [CSS_PROPERTIES.mt]: SXProperty<CSSUnitValue>;
    [CSS_PROPERTIES.ml]: SXProperty<CSSUnitValue>;
    [CSS_PROPERTIES.mr]: SXProperty<CSSUnitValue>;
    [CSS_PROPERTIES.mb]: SXProperty<CSSUnitValue>;
    [CSS_PROPERTIES.p]: SXProperty<CSSUnitValue>;
    [CSS_PROPERTIES.px]: SXProperty<CSSUnitValue>;
    [CSS_PROPERTIES.py]: SXProperty<CSSUnitValue>;
    [CSS_PROPERTIES.pt]: SXProperty<CSSUnitValue>;
    [CSS_PROPERTIES.pl]: SXProperty<CSSUnitValue>;
    [CSS_PROPERTIES.pr]: SXProperty<CSSUnitValue>;
    [CSS_PROPERTIES.pb]: SXProperty<CSSUnitValue>;

    // Flex and Grid properties
    [CSS_PROPERTIES.flex]: SXProperty<CSSProperties['flex']>;
    [CSS_PROPERTIES.flexDirection]: SXProperty<CSSProperties['flexDirection']>;
    [CSS_PROPERTIES.flexWrap]: SXProperty<CSSProperties['flexWrap']>;
    [CSS_PROPERTIES.flexFlow]: SXProperty<CSSProperties['flexFlow']>;
    [CSS_PROPERTIES.flexGrow]: SXProperty<CSSProperties['flexGrow']>;
    [CSS_PROPERTIES.flexShrink]: SXProperty<CSSProperties['flexShrink']>;
    [CSS_PROPERTIES.flexBasis]: SXProperty<CSSProperties['flexBasis']>;

    [CSS_PROPERTIES.grid]: SXProperty<CSSProperties['grid']>;
    [CSS_PROPERTIES.gridTemplateColumns]: SXProperty<CSSProperties['gridTemplateColumns']>;
    [CSS_PROPERTIES.gridTemplateRows]: SXProperty<CSSProperties['gridTemplateRows']>;
    [CSS_PROPERTIES.gridAutoRows]: SXProperty<CSSProperties['gridAutoRows']>;
    [CSS_PROPERTIES.gridAutoColumns]: SXProperty<CSSProperties['gridAutoColumns']>;
    [CSS_PROPERTIES.gridAutoFlow]: SXProperty<CSSProperties['gridAutoFlow']>;
    [CSS_PROPERTIES.gridTemplateAreas]: SXProperty<CSSProperties['gridTemplateAreas']>;
    [CSS_PROPERTIES.gridTemplate]: SXProperty<CSSProperties['gridTemplate']>;
    [CSS_PROPERTIES.gridArea]: SXProperty<CSSProperties['gridArea']>;
    [CSS_PROPERTIES.gridRow]: SXProperty<CSSProperties['gridRow']>;
    [CSS_PROPERTIES.gridColumn]: SXProperty<CSSProperties['gridColumn']>;
    [CSS_PROPERTIES.gridColumnStart]: SXProperty<CSSProperties['gridColumnStart']>;
    [CSS_PROPERTIES.gridColumnEnd]: SXProperty<CSSProperties['gridColumnEnd']>;
    [CSS_PROPERTIES.gridRowStart]: SXProperty<CSSProperties['gridRowStart']>;
    [CSS_PROPERTIES.gridRowEnd]: SXProperty<CSSProperties['gridRowEnd']>;

    [CSS_PROPERTIES.gap]: SXProperty<CSSProperties['gap']>;
    [CSS_PROPERTIES.rowGap]: SXProperty<CSSProperties['rowGap']>;
    [CSS_PROPERTIES.columnGap]: SXProperty<CSSProperties['columnGap']>;
    [CSS_PROPERTIES.alignItems]: SXProperty<CSSProperties['alignItems']>;
    [CSS_PROPERTIES.alignContent]: SXProperty<CSSProperties['alignContent']>;
    [CSS_PROPERTIES.alignSelf]: SXProperty<CSSProperties['alignSelf']>;
    [CSS_PROPERTIES.justifyItems]: SXProperty<CSSProperties['justifyItems']>;
    [CSS_PROPERTIES.justifyContent]: SXProperty<CSSProperties['justifyContent']>;
    [CSS_PROPERTIES.justifySelf]: SXProperty<CSSProperties['justifySelf']>;
    [CSS_PROPERTIES.placeItems]: SXProperty<CSSProperties['placeItems']>;
    [CSS_PROPERTIES.placeContent]: SXProperty<CSSProperties['placeContent']>;
    [CSS_PROPERTIES.placeSelf]: SXProperty<CSSProperties['placeSelf']>;
}

export interface IBoxProps {
    className?: string;
    style?: CSSProperties;
    sx?: Partial<Isx>;
    intercom_target?: string;
}

export const CSS_PROPERTY_NAMES_MAP: Record<CSS_PROPERTIES, Array<string>> = {
    [CSS_PROPERTIES.display]: ['display'],
    [CSS_PROPERTIES.height]: ['height'],
    [CSS_PROPERTIES.width]: ['width'],
    [CSS_PROPERTIES.backgroundColor]: ['background-color'],
    [CSS_PROPERTIES.position]: ['position'],

    [CSS_PROPERTIES.m]: ['margin'],
    [CSS_PROPERTIES.mx]: ['margin-left', 'margin-right'],
    [CSS_PROPERTIES.my]: ['margin-top', 'margin-bottom'],
    [CSS_PROPERTIES.mt]: ['margin-top'],
    [CSS_PROPERTIES.mb]: ['margin-bottom'],
    [CSS_PROPERTIES.ml]: ['margin-left'],
    [CSS_PROPERTIES.mr]: ['margin-right'],
    [CSS_PROPERTIES.p]: ['padding'],
    [CSS_PROPERTIES.px]: ['padding-left', 'padding-right'],
    [CSS_PROPERTIES.py]: ['padding-top', 'padding-bottom'],
    [CSS_PROPERTIES.pt]: ['padding-top'],
    [CSS_PROPERTIES.pb]: ['padding-bottom'],
    [CSS_PROPERTIES.pl]: ['padding-left'],
    [CSS_PROPERTIES.pr]: ['padding-right'],

    [CSS_PROPERTIES.flex]: ['flex'],
    [CSS_PROPERTIES.flexDirection]: ['flex-direction'],
    [CSS_PROPERTIES.flexWrap]: ['flex-wrap'],
    [CSS_PROPERTIES.flexFlow]: ['flex-flow'],
    [CSS_PROPERTIES.flexGrow]: ['flex-grow'],
    [CSS_PROPERTIES.flexShrink]: ['flex-shrink'],
    [CSS_PROPERTIES.flexBasis]: ['flex-basis'],

    [CSS_PROPERTIES.grid]: ['grid'],
    [CSS_PROPERTIES.gridTemplateColumns]: ['grid-template-columns'],
    [CSS_PROPERTIES.gridTemplateRows]: ['grid-template-rows'],
    [CSS_PROPERTIES.gridAutoRows]: ['grid-auto-rows'],
    [CSS_PROPERTIES.gridAutoColumns]: ['grid-auto-columns'],
    [CSS_PROPERTIES.gridAutoFlow]: ['grid-auto-flow'],
    [CSS_PROPERTIES.gridTemplateAreas]: ['grid-template-areas'],
    [CSS_PROPERTIES.gridTemplate]: ['grid-template'],
    [CSS_PROPERTIES.gridArea]: ['grid-area'],
    [CSS_PROPERTIES.gridRow]: ['grid-row'],
    [CSS_PROPERTIES.gridColumn]: ['grid-column'],
    [CSS_PROPERTIES.gridColumnStart]: ['grid-column-start'],
    [CSS_PROPERTIES.gridColumnEnd]: ['grid-column-end'],
    [CSS_PROPERTIES.gridRowStart]: ['grid-row-start'],
    [CSS_PROPERTIES.gridRowEnd]: ['grid-row-end'],

    [CSS_PROPERTIES.gap]: ['gap'],
    [CSS_PROPERTIES.rowGap]: ['row-gap'],
    [CSS_PROPERTIES.columnGap]: ['column-gap'],
    [CSS_PROPERTIES.alignItems]: ['align-items'],
    [CSS_PROPERTIES.alignContent]: ['align-content'],
    [CSS_PROPERTIES.alignSelf]: ['align-self'],
    [CSS_PROPERTIES.justifyItems]: ['justify-items'],
    [CSS_PROPERTIES.justifyContent]: ['justify-content'],
    [CSS_PROPERTIES.justifySelf]: ['justify-self'],
    [CSS_PROPERTIES.placeItems]: ['place-items'],
    [CSS_PROPERTIES.placeContent]: ['place-content'],
    [CSS_PROPERTIES.placeSelf]: ['place-self'],
};

export const PROPERTIES_WITH_UNIT_VALUES = new Set([
    CSS_PROPERTIES.m,
    CSS_PROPERTIES.mx,
    CSS_PROPERTIES.my,
    CSS_PROPERTIES.mt,
    CSS_PROPERTIES.ml,
    CSS_PROPERTIES.mr,
    CSS_PROPERTIES.mb,
    CSS_PROPERTIES.p,
    CSS_PROPERTIES.px,
    CSS_PROPERTIES.py,
    CSS_PROPERTIES.pt,
    CSS_PROPERTIES.pl,
    CSS_PROPERTIES.pr,
    CSS_PROPERTIES.pb,
]);

// Creating a component like such gives us a way to leverage styled component which uses a package called stylis under-the-hood which is more performant than using the raw react styles api
const BlankStyledComponent = styled.div<{ css: string }>`
    ${(props): string => props.css}
`;

export function Box(props: PropsWithChildren<IBoxProps>): ReactElement {
    const { children, className, style, sx = {}, intercom_target } = props;

    // TODO investigate if using useMemo will yield better performance. Is the memory cost worth the improved computation
    const css: string = Object.entries(sx || {}).reduce(
        (acc, [property, value]) =>
            acc +
            generateCSSDeclaration({
                property: property as CSS_PROPERTIES,
                value: value as number | string | IBoxBreakPoints<number | string>,
            }),
        // init empty string
        ''
    );

    return (
        <BlankStyledComponent
            className={className}
            style={style}
            css={css}
            data-intercom-target={intercom_target}
        >
            {children}
        </BlankStyledComponent>
    );
}

export const Row = styled(Box)`
    display: flex;
    flex-direction: row;
`;

export const Col = styled(Box)`
    display: flex;
    flex-direction: column;
`;

export const Grid = styled(Box)`
    display: grid;
`;
