export enum CampaignObjective {
    REVIEWS = 'REVIEWS',
    RECOMMENDATIONS = 'RECOMMENDATIONS',
    EMPLOYEE_REVIEWS = 'EMPLOYEE_REVIEWS',
    ENGAGEMENT = 'ENGAGEMENT',
    BOOKINGS = 'BOOKINGS',
    WELCOME_NEW_CUSTOMERS = 'WELCOME_NEW_CUSTOMERS',
    REWARD_LOYAL_CUSTOMERS = 'REWARD_LOYAL_CUSTOMERS',
    REPEAT_BUSINESS = 'REPEAT_BUSINESS',
    ATOM_REFERRALS = 'ATOM_REFERRALS',
}

export enum CampaignSubjectKind {
    Person = 'Person',
    Story = 'Story',
    Employee = 'Employee',
    User = 'User',
}

export enum CampaignRuleType {
    SINGLE = 'SINGLE',
    AGGREGATE = 'AGGREGATE',
}

export enum CampaignRuleCalcMethod {
    SUM = 'SUM',
    MEAN = 'MEAN',
    MEDIAN = 'MEDIAN',
    MODE = 'MODE',
}

export enum CampaignRuleOperator {
    GREATER_THAN_OR_EQUAL_TO = 'GREATER_THAN_OR_EQUAL_TO',
    GREATER_THAN = 'GREATER_THAN',
    EQUAL_TO = 'EQUAL_TO',
    LESS_THAN = 'LESS_THAN',
    LESS_THAN_OR_EQUAL_TO = 'LESS_THAN_OR_EQUAL_TO',
    NOT_EQUAL_TO = 'NOT_EQUAL_TO',
    CONTAINS = 'CONTAINS',
    DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN',
    IS_WITHIN_THE_LAST = 'IS_WITHIN_THE_LAST',
    IS_BEYOND_THE_LAST = 'IS_BEYOND_THE_LAST',
    IS_AFTER = 'IS_AFTER',
    IS_BEFORE = 'IS_BEFORE',
}

export enum DefaultCampaign {
    GET_REVIEWS = 'GET_REVIEWS',
    RECOMMENDATIONS = 'RECOMMENDATIONS',
    BOOKINGS = 'BOOKINGS',
    GET_REPEAT_BUSINESS = 'GET_REPEAT_BUSINESS',
    ATOM_REFERRALS = 'ATOM_REFERRALS',
}
