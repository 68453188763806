import styled from 'styled-components';
import { ISheetsPresent, IStyledSheetWrapper, sheetsPresent } from './Layout';

// TODO: eliminate StyledSectionCanvas. This should be deprecated in favour StyledPage below.

export const StyledSectionCanvas = styled.div`
    position: relative;
    z-index: 1;
    display: flex;
    overflow: visible;
    padding-top: 60px;
`;

// Styled Page and SheetWrapper

export const StyledPage = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
`;

// --- SHEETS ---

export const StyledSheetWrapper = styled.div<IStyledSheetWrapper>`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    @media screen and ${(props): string => props.theme.legacy_screen_size.mobile_vertical} {
        flex-direction: ${(props): string =>
            sheetsPresent(props.sheets as ISheetsPresent) === 'right_sheet_only'
                ? 'column-reverse'
                : 'row'};
        height: auto;
    }
`;

// --- SHEETS ---

// --> LSO: Left Sheet Only
//TODO: use the show_left_sheet_on_mobile prop in ILeftSheet to decide when/how to show the left sheet.

export const StyledLSOLeftSheet = styled.div`
    min-width: 210px;
    display: none;
    @media screen and ${(props): string => props.theme.legacy_screen_size.desktop} {
        display: block;
        padding-right: 32px;
    }
    @media screen and ${(props): string => props.theme.legacy_screen_size.tablet} {
        display: block;
        padding-right: 32px;
    }
    @media screen and ${(props): string => props.theme.legacy_screen_size.mobile_horizontal} {
        padding-right: 16px;
    }
    @media screen and ${(props): string => props.theme.legacy_screen_size.mobile_vertical} {
        padding-right: 16px;
    }
`;

export const StyledLSOMainSheet = styled.div`
    flex-grow: 1;
    @media screen and ${(props): string => props.theme.legacy_screen_size.desktop} {
        padding: 32px;
    }
    @media screen and ${(props): string => props.theme.legacy_screen_size.tablet} {
        padding: 16px 32px 0px 0px;
    }
`;

// --> RSO: Right Sheet Only

export const StyledRSORightSheet = styled.div`
    flex: 0 1 300px;
    padding-right: 32px;

    @media screen and ${(props): string => props.theme.legacy_screen_size.tablet} {
        flex: 0 1 200px;
        padding: 0px;
    }

    @media screen and (max-width: ${(props): number =>
            props.theme.legacyMediaWidth.mobile_horizontal}px) {
        display: none;
    }
`;

export const StyledRSOMainSheet = styled.div`
    flex-grow: 1;
    @media screen and ${(props): string => props.theme.legacy_screen_size.desktop} {
        padding: 32px;
    }
    @media screen and ${(props): string => props.theme.legacy_screen_size.tablet} {
        padding: 32px 24px;
        min-width: calc(
            ${(props): number => props.theme.legacyMediaWidth.mobile_horizontal}px + 24px + 24px
        );
    }
    @media screen and ${(props): string => props.theme.legacy_screen_size.mobile_horizontal} {
        padding: 32px 32px 32px 24px;
        min-width: calc(
            ${(props): number => props.theme.legacyMediaWidth.mobile_horizontal}px + 24px + 32px
        );
    }
    @media screen and ${(props): string => props.theme.legacy_screen_size.mobile_vertical} {
        padding: 32px 16px;
        flex: 5;
    }
`;

// --> MSO: Main Sheet Only

export const StyledMSOMainSheet = styled.div`
    flex-grow: 1;
    @media screen and ${(props): string => props.theme.legacy_screen_size.desktop} {
        padding: 32px;
        /* background-color: blue; */
    }
    @media screen and ${(props): string => props.theme.legacy_screen_size.tablet} {
        padding: 16px 24px;
        /* background-color: red; */
    }
    @media screen and ${(props): string => props.theme.legacy_screen_size.mobile_horizontal} {
        padding: 16px 0px;
        /* background-color: green; */
    }
    @media screen and ${(props): string => props.theme.legacy_screen_size.mobile_vertical} {
        padding: 16px 0px;
        /* background-color: yellow; */
    }
`;

// --> AS: All Sheets
//TODO: use the show_left_sheet_on_mobile prop in ILeftSheet to decide when/how to show the left sheet.

export const StyledASLeftSheet = styled.div`
    min-width: 210px;
    display: none;
    @media screen and ${(props): string => props.theme.legacy_screen_size.desktop} {
        display: block;
        padding-right: 32px;
    }
    @media screen and ${(props): string => props.theme.legacy_screen_size.tablet} {
        display: block;
        padding-right: 32px;
    }
    @media screen and ${(props): string => props.theme.legacy_screen_size.mobile_horizontal} {
        padding-right: 16px;
    }
    @media screen and ${(props): string => props.theme.legacy_screen_size.mobile_vertical} {
        padding-right: 16px;
    }
`;

export const StyledASMainSheet = styled.div`
    flex-grow: 1;
    @media screen and ${(props): string => props.theme.legacy_screen_size.desktop} {
        padding: 32px;
    }
    @media screen and ${(props): string => props.theme.legacy_screen_size.tablet} {
        padding: 16px 32px 0px 0px;
    }
`;

export const StyledASRightSheet = styled.div`
    flex: 0 1 300px;
    display: none;
    @media screen and ${(props): string => props.theme.legacy_screen_size.desktop} {
        display: block;
        padding-right: 32px;
    }
`;
