// matches all URLs or emails EXCEPT those enclosed in a style tag
export const URL_OR_EMAIL_PATTERN = /(https?:\/\/(?:www\.)?)?[-a-zA-Z0-9@\^\$:%\.“”'_\+~#=]{1,256}\.([A-Za-z0-9()]{1,6}[a-zA-Z]+)\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=>]*)(?![^\<\>]*\<\/style\>)/;
export const BANNED_WORD_LIST: Set<string> = new Set<string>([
    'vape',
    'vapes',
    'venmo',
    'kratom',
    'cbd',
]);
export const BASE_ENTITY_VALIDATION_INPUT = {
    job_ids: [],
    employee_ids: [],
    person_ids: [],
    photo_ids: [],
    catalog_product_ids: [],
    invoice_ids: [],
    company_id: '',
};
