import { IButtonTheme } from './core/button/Button';
import { IIconButtonTheme } from './core/icon-button/IconButton';
import { IAlert, IApp, IChart, IChip, ILegacyScreenSizes, IScreenSizes, ISidebar } from './types';

export type AppTheme = typeof theme;

const theme = {
    name: 'NiceJob Theme',
    // Primitives such as colors, fonts, and spacing
    colors: {
        grey900: '#1E2024',
        grey800: '#32363D',
        grey700: '#575F6B',
        grey600: '#828B98',
        grey500: '#A6ACB6',
        grey400: '#CACED4',
        grey300: '#DDDFE3',
        grey250: '#E5E5E5',
        grey200: '#ECEEF0',
        grey100: '#F4F5F6',
        grey50: '#FAFAFB',

        blue950: '#00132E',
        blue900: '#061E42',
        blue800: '#013179',
        blue700: '#0146AC',
        blue600: '#025BDE',
        blue500: '#1874FD',
        blue400: '#4991FD',
        blue300: '#7CB0FE',
        blue200: '#AECEFE',
        blue100: '#E1EDFF',
        blue50: '#F5F9FF',

        green900: '#083A20',
        green800: '#0F6638',
        green700: '#169350',
        green600: '#1CBF68',
        green500: '#2CE080',
        green400: '#5AE79C',
        green300: '#87EDB7',
        green200: '#B3F4D1',
        green100: '#E0FAEC',
        green50: '#F6FEFA',

        gold900: '#332900',
        gold800: '#665200',
        gold700: '#997A00',
        gold600: '#CCA300',
        gold500: '#FFCC00',
        gold400: '#FFD633',
        gold300: '#FFE066',
        gold200: '#FFEB99',
        gold100: '#FFF5CC',
        gold50: '#FFFAE5',

        red900: '#520300',
        red800: '#840500',
        red700: '#B80700',
        red600: '#EB0900',
        red500: '#FF281F',
        red400: '#FF5851',
        red300: '#FF8984',
        red200: '#FFBAB8',
        red100: '#FFEBEB',
        red50: '#FFFAFA',

        purple900: '#1B053D',
        purple800: '#2F096D',
        purple700: '#430D9C',
        purple600: '#5810CB',
        purple500: '#6F21ED',
        purple400: '#8F52F1',
        purple300: '#AC80F5',
        purple200: '#CBAFF8',
        purple100: '#EADEFC',
        purple50: '#F9F6FE',

        white: '#FFFFFF',
        black: '#000000',
        annotation: '#E8178A',
        googleBlue: '#4285f4',
        googleBlueActive: '#3367d6',
        paystoneBlue: '#4371e3',
        paystoneBlueBackground: '#051b35',
        paystoneGrey: '#6a6a6a',
        typography: '#364459',
        xeroBlue: '#13B5EA',
    },
    rgb_colors: {
        blue300: { r: 124, g: 176, b: 254 },
    },
    elevations: {
        // source: https://gist.github.com/serglo/f9f0be9a66fd6755a0bda85f9c64e85f
        z0: '0px 0px 8px #1874FD',
        /* Shadow 1dp */
        z1:
            '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',

        /* Shadow 2dp */
        z2:
            '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20)',

        /* Shadow 3dp */
        z3:
            '0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.20)',

        /* Shadow 4dp */
        z4:
            '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20)',

        /* Shadow 6dp */
        z6:
            '0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.20)',

        /* Shadow 8dp */
        z8:
            '0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20)',

        /* Shadow 9dp */
        z9:
            '0 9px 12px 1px rgba(0,0,0,0.14), 0 3px 16px 2px rgba(0,0,0,0.12), 0 5px 6px -3px rgba(0,0,0,0.20)',

        /* Shadow 12dp */
        z12:
            '0 12px 17px 2px rgba(0,0,0,0.14), 0 5px 22px 4px rgba(0,0,0,0.12), 0 7px 8px -4px rgba(0,0,0,0.20)',

        /* Shadow 16dp */
        z16:
            '0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.20)',

        /* Shadow 24dp */
        z24:
            '0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.20)',
    },
    filters: {
        grey600:
            'invert(63%) sepia(0%) saturate(0%) hue-rotate(81deg) brightness(89%) contrast(87%)',
    },
    spacings: {
        s1: '4px',
        s2: '8px',
        s3: '12px',
        s4: '16px',
        s5: '24px',
        s6: '32px',
        s7: '40px',
        s8: '48px',
        s9: '56px',
        s10: '64px',
        s11: '72px',
    },
    zIndex: {
        zNegative: -1,
        z1: 1,
        z2: 2,
        z3: 3,
        z4: 4,
        z5: 5,
        z6: 6,
        z7: 7,
        z8: 8,
        z9: 9,
        z100: 100, // For modals
    },
    legacyMediaWidth: {
        mobile_vertical: 0,
        mobile_horizontal: 380,
        tablet: 767,
        desktop: 991,
    },
    get legacy_screen_size(): ILegacyScreenSizes {
        return {
            desktop: `(min-width: ${this.legacyMediaWidth.desktop}px)`,
            tablet: `(max-width: ${this.legacyMediaWidth.desktop}px) and (min-width: ${this.legacyMediaWidth.tablet}px)`,
            mobile_horizontal: `(max-width: ${this.legacyMediaWidth.tablet}px) and (min-width: ${this.legacyMediaWidth.mobile_horizontal}px)`,
            mobile_vertical: `(max-width: ${this.legacyMediaWidth.mobile_horizontal}px) and (min-width: ${this.legacyMediaWidth.mobile_vertical}px)`,
        };
    },
    responsiveBreakPoints: {
        mobile_vertical: 0,
        mobile_horizontal: 380,
        tablet: 767,
        desktop: 991,
        large_desktop: 1280,
    },
    get responsive_media_queries(): IScreenSizes {
        return {
            large_desktop: `(min-width: ${this.responsiveBreakPoints.large_desktop}px)`,
            desktop: `(min-width: ${this.responsiveBreakPoints.desktop}px)`,
            tablet: `(min-width: ${this.responsiveBreakPoints.tablet}px)`,
            mobile_horizontal: `(min-width: ${this.responsiveBreakPoints.mobile_horizontal}px)`,
        };
    },

    // Component specific theming
    get app(): IApp {
        return {
            background: this.colors.grey50,
        };
    },
    get alert(): IAlert {
        return {
            neutral: {
                background: this.colors.blue50,
                color: this.colors.blue700,
            },
            confirmation: {
                background: this.colors.purple50,
                color: this.colors.purple700,
            },
            unhappy: {
                background: this.colors.gold100,
                color: this.colors.gold800,
            },
            serious: {
                background: this.colors.red100,
                color: this.colors.red700,
            },
            success: {
                background: this.colors.green100,
                color: this.colors.green700,
            },
        };
    },
    get button(): IButtonTheme {
        return {
            action: {
                default: {
                    color: this.colors.white,
                    background: this.colors.blue500,
                },
                hover: {
                    color: this.colors.white,
                    background: this.colors.blue600,
                },
                active: {
                    color: this.colors.white,
                    background: this.colors.blue700,
                },
                disabled: {
                    color: this.colors.white,
                    background: this.colors.blue200,
                },
                loading: {
                    color: this.colors.white,
                    background: this.colors.blue500,
                },
                loading_dot_colors: [
                    this.colors.blue100,
                    this.colors.blue200,
                    this.colors.blue300,
                    this.colors.blue400,
                ],
            },
            checked: {
                default: {
                    color: this.colors.black,
                    background: this.colors.green500,
                },
                hover: {
                    color: this.colors.black,
                    background: this.colors.green500,
                },
                active: {
                    color: this.colors.black,
                    background: this.colors.green500,
                },
                disabled: {
                    color: this.colors.black,
                    background: this.colors.green500,
                },
                loading: {
                    color: this.colors.black,
                    background: this.colors.green500,
                },
                loading_dot_colors: [
                    this.colors.green100,
                    this.colors.green200,
                    this.colors.green300,
                    this.colors.green400,
                ],
            },
            emphasis: {
                default: {
                    color: this.colors.white,
                    background: this.colors.purple500,
                },
                hover: {
                    color: this.colors.white,
                    background: this.colors.purple600,
                },
                active: {
                    color: this.colors.white,
                    background: this.colors.purple700,
                },
                disabled: {
                    color: this.colors.white,
                    background: this.colors.purple200,
                },
                loading: {
                    color: this.colors.white,
                    background: this.colors.purple500,
                },
                loading_dot_colors: [
                    this.colors.purple100,
                    this.colors.purple200,
                    this.colors.purple300,
                    this.colors.purple400,
                ],
            },
            phantom: {
                default: {
                    color: this.colors.blue400,
                    background: 'transparent',
                    border: `1px solid ${this.colors.blue400}`,
                },
                hover: {
                    color: this.colors.blue300,
                    background: 'transparent',
                    border: `1px solid ${this.colors.blue200}`,
                },
                active: {
                    color: this.colors.blue200,
                    background: 'transparent',
                    border: `1px solid ${this.colors.blue100}`,
                },
                disabled: {
                    color: this.colors.grey700,
                    background: 'transparent',
                    border: `1px solid ${this.colors.grey600}`,
                },
                loading: {
                    color: this.colors.blue400,
                    background: 'transparent',
                    border: `1px solid ${this.colors.blue300}`,
                },
                loading_dot_colors: [
                    this.colors.blue100,
                    this.colors.blue200,
                    this.colors.blue300,
                    this.colors.blue400,
                ],
            },
            ghost: {
                default: {
                    color: this.colors.blue600,
                    background: 'transparent',
                    border: `1px solid ${this.colors.blue300}`,
                },
                hover: {
                    color: this.colors.blue700,
                    background: this.colors.blue50,
                    border: `1px solid ${this.colors.blue300}`,
                },
                active: {
                    color: this.colors.blue800,
                    background: this.colors.blue100,
                    border: `1px solid ${this.colors.blue300}`,
                },
                disabled: {
                    color: this.colors.blue300,
                    background: this.colors.white,
                    border: `1px solid ${this.colors.blue200}`,
                },
                loading: {
                    color: this.colors.blue600,
                    background: this.colors.white,
                    border: `1px solid ${this.colors.blue300}`,
                },
                loading_dot_colors: [
                    this.colors.blue100,
                    this.colors.blue200,
                    this.colors.blue300,
                    this.colors.blue400,
                ],
            },
            subtle: {
                default: {
                    color: this.colors.grey700,
                    background: this.colors.white,
                    border: `1px solid ${this.colors.grey400}`,
                },
                hover: {
                    color: this.colors.grey800,
                    background: this.colors.grey50,
                    border: `1px solid ${this.colors.grey400}`,
                },
                active: {
                    color: this.colors.grey800,
                    background: this.colors.grey100,
                    border: `1px solid ${this.colors.grey400}`,
                },
                disabled: {
                    color: this.colors.grey500,
                    background: this.colors.white,
                    border: `1px solid ${this.colors.grey300}`,
                },
                loading: {
                    color: this.colors.grey700,
                    background: this.colors.white,
                    border: `1px solid ${this.colors.grey400}`,
                },
                loading_dot_colors: [
                    this.colors.grey100,
                    this.colors.grey200,
                    this.colors.grey300,
                    this.colors.grey400,
                ],
            },
            warning: {
                default: {
                    color: this.colors.red500,
                    background: this.colors.white,
                    border: `1px solid ${this.colors.red200}`,
                },
                hover: {
                    color: this.colors.red600,
                    background: this.colors.red50,
                    border: `1px solid ${this.colors.red200}`,
                },
                active: {
                    color: this.colors.red700,
                    background: this.colors.red100,
                    border: `1px solid ${this.colors.red200}`,
                },
                disabled: {
                    color: this.colors.red300,
                    background: this.colors.white,
                    border: `1px solid ${this.colors.red200}`,
                },
                loading: {
                    color: this.colors.red500,
                    background: this.colors.white,
                    border: `1px solid ${this.colors.red200}`,
                },
                loading_dot_colors: [
                    this.colors.red100,
                    this.colors.red200,
                    this.colors.red300,
                    this.colors.red400,
                ],
            },
        };
    },
    get chart(): IChart {
        return {
            stroke: {
                blue: this.colors.blue500,
                green: this.colors.green400,
                purple: this.colors.purple200,
                red: this.colors.red400,
            },
            soft: {
                blue: this.colors.blue100,
                green: this.colors.green50,
                purple: this.colors.purple100,
                red: this.colors.red50,
            },
            medium: {
                blue: this.colors.blue200,
                green: this.colors.green300,
                purple: this.colors.purple200,
                red: this.colors.red300,
            },
        };
    },
    get chip(): IChip {
        return {
            default: {
                text: this.colors.grey900,
                background: this.colors.grey100,
                onHover: this.colors.grey200,
            },
            blue: {
                text: this.colors.blue500,
                background: this.colors.blue50,
            },
            vibrant_blue: {
                text: this.colors.white,
                background: this.colors.blue500,
            },
            red: {
                text: this.colors.red700,
                background: this.colors.red50,
            },
            green: {
                text: this.colors.green700,
                background: this.colors.green100,
            },
            // for use on dark backgrounds
            dark_green: {
                text: this.colors.blue900,
                background: this.colors.green500,
            },
            gold: {
                text: this.colors.gold800,
                background: this.colors.gold50,
            },
            purple: {
                text: this.colors.purple600,
                background: this.colors.purple50,
            },
        };
    },
    get icon_button(): IIconButtonTheme {
        return {
            action: {
                default: {
                    color: this.colors.blue500,
                    background: this.colors.white,
                },
                hover: {
                    color: this.colors.blue600,
                    background: this.colors.grey50,
                },
                active: {
                    color: this.colors.blue700,
                    background: this.colors.grey100,
                },
                disabled: {
                    color: this.colors.blue300,
                    background: this.colors.white,
                },
                loading: {
                    color: this.colors.blue500,
                    background: this.colors.white,
                },
            },
            subtle: {
                default: {
                    color: this.colors.grey500,
                    background: this.colors.white,
                },
                hover: {
                    color: this.colors.grey600,
                    background: this.colors.grey50,
                },
                active: {
                    color: this.colors.grey700,
                    background: this.colors.grey100,
                },
                disabled: {
                    color: this.colors.grey300,
                    background: this.colors.white,
                },
                loading: {
                    color: this.colors.grey500,
                    background: this.colors.white,
                },
            },
            navigation: {
                default: {
                    color: this.colors.grey800,
                    background: this.colors.white,
                },
                hover: {
                    color: this.colors.grey800,
                    background: this.colors.white,
                },
                active: {
                    color: this.colors.grey800,
                    background: this.colors.white,
                },
                disabled: {
                    color: this.colors.grey400,
                    background: this.colors.white,
                },
                loading: {
                    color: this.colors.grey800,
                    background: this.colors.white,
                },
            },
        };
    },
    get sidebar(): ISidebar {
        return {
            background: this.colors.blue900,
            separator: this.colors.white + `26`, //15% opacity
            color: this.colors.grey400,
            active: {
                background: '#031125',
                color: this.colors.grey200,
            },
            hover: {
                background: '#031125',
                color: this.colors.grey200,
            },
        };
    },
};

export default theme;
