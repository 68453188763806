import styled, { DefaultTheme } from 'styled-components';
import { InputFieldProps, IStyledInputPortionProps } from './InputField';

// Styled components

export const StyledInputField = styled.div<InputFieldProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    height: 36px;
    width: ${(props): string => inputFieldWidth(props)};
    background-color: ${(props): string => props.background_color || props.theme.colors.grey50};
    border-radius: 4px 4px 0px 0px;
    border: none;
    border-bottom: ${(props): string => inputFieldBorder(props)};
    box-sizing: border-box;

    input {
        font-size: 16px;
        font-family: Inter, Helvetica, sans-serif;
    }

    &:hover {
        border-bottom: 1px solid ${(props): string => props.theme.colors.grey900};
    }
    &:active {
        border-bottom: 1px solid ${(props): string => props.theme.colors.blue500};
    }
    &:focus-within {
        border-bottom: 1px solid ${(props): string => props.theme.colors.blue500};
    }
    > *:not(:last-child) {
        margin-right: 8px;
    }
`;

export const StyledInputPortion = styled.input`
    padding: 0; // reset the padding so it won't override by browser
    border: none;
    background-color: transparent;
    color: ${(props): string => props.theme.colors.grey900};
    height: 100%;
    width: 100%;
    ::placeholder {
        color: ${(props): string => props.theme.colors.grey600};
    }
    :focus {
        outline: none;
    }
`;

export const StyledLoading = styled.img`
    height: 35px;
    width: 35px;
`;

export const StyledIconWrapper = styled.div`
    width: 24px;
    height: 24px;
`;

// Helpers
function inputFieldWidth(props: InputFieldProps): string {
    const { variant } = props;

    switch (variant) {
        case 'x-small':
            return '50px';
        case 'small':
            return '80px';
        case 'medium':
            return '160px';
        case 'large':
            return '320px';
        case 'stretch':
            return '100%';
        default:
            return '160px';
    }
}

function inputFieldBorder(props: {
    disabled?: boolean;
    invalid?: boolean;
    valid?: boolean;
    theme: DefaultTheme;
}): string {
    const { disabled, invalid, valid, theme } = props;

    if (disabled) {
        return 'none';
    } else if (invalid) {
        return `1px solid ${theme.colors.red600}`;
    } else if (valid) {
        return `1px solid ${theme.colors.green500}`;
    }

    return `1px solid ${theme.colors.grey400}`;
}
