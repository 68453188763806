import { WidgetMethod, WidgetName } from '../../types/sdk';

/**
 * SDK version
 * - Must be updated any time the client SDK is updated!
 */
export const SDK_FILE_VERSION = 111;

/**
 * SDK globals
 */

//  Widget data
export const WIDGET_DATA_MAX_AGE_IN_S = 60 * 60; // 1 hour
export const WIDGET_JSONLD_MAX_AGE_IN_S = 60 * 60 * 12; // 12 hours

//  Widget names
export const STORIES = 'STORIES';
export const MORE_STORIES = 'MORE_STORIES';
export const FEATURED_STORY = 'FEATURED_STORY';
export const LEAD_FORM = 'LEAD_FORM';
export const RECOMMENDATION = 'RECOMMENDATION';
export const ATOM_REFERRALS = 'ATOM_REFERRALS';
export const ATOM_REFERRALS_LANDING = 'ATOM_REFERRALS_LANDING';
export const REFERRAL_LEAD_FORM = 'REFERRAL_LEAD_FORM';
export const REVIEW_FORM = 'REVIEW_FORM';
export const TRUST_BADGE = 'TRUST_BADGE';
export const SOCIAL_PROOF = 'SOCIAL_PROOF';
export const GOALS = 'GOALS';
export const NICEJOB_REVIEW_FORM = 'NICEJOB_REVIEW_FORM';
export const NPS_SURVEY = 'NPS_SURVEY';

//  Widget data
export const REVIEW_FORM_CLASS = 'nj-review';

export const WIDGETS: {
    [key in WidgetName]: {
        classes: string[];
        loading?: boolean;
        method?: WidgetMethod;
    };
} = {
    [STORIES]: {
        classes: ['nj-stories', 'nj-story', 'nicejob-stories'],
        loading: true,
    },
    [FEATURED_STORY]: {
        classes: ['nj-featured-story'],
        loading: true,
    },
    [LEAD_FORM]: {
        classes: ['nj-lead', 'h-lead'],
        method: 'SIBLING',
    },
    [RECOMMENDATION]: {
        classes: ['nj-recommendation', 'nj-recommend'],
        method: 'SIBLING',
    },
    [ATOM_REFERRALS]: {
        classes: ['nj-atom-referrals', 'nj-atom-referral'],
        method: 'SIBLING',
    },
    [ATOM_REFERRALS_LANDING]: {
        classes: ['nj-atom-referrals-landing', 'nj-atom-referral-landing'],
        method: 'SIBLING',
    },
    [REVIEW_FORM]: {
        classes: [REVIEW_FORM_CLASS, 'n-review'],
        method: 'SIBLING',
    },
    [TRUST_BADGE]: {
        classes: ['nj-badge', 'nicejob-badge'],
        loading: true,
    },
    [SOCIAL_PROOF]: {
        classes: ['nj-social-proof', 'nj-engage'],
    },
    [GOALS]: {
        classes: ['nj-goals'],
        loading: true,
    },
    [REFERRAL_LEAD_FORM]: {
        classes: ['nj-referral-lead-form'],
        method: 'SIBLING',
    },
    [NPS_SURVEY]: {
        classes: ['nj-nps'],
        method: 'SIBLING',
    },
};

let _TOP_LEVEL_CLASSES: Array<string> = [];
for (const k in WIDGETS) {
    _TOP_LEVEL_CLASSES = [..._TOP_LEVEL_CLASSES, ...WIDGETS[k as WidgetName].classes];
}
export const TOP_LEVEL_CLASSES = _TOP_LEVEL_CLASSES;

// Tracking
export const TRACKING_METADATA_ATTR = 'data-nj-tracking-metadata';
export const CLICK_TRACKING_CLASS = 'nj-click';

//  Paths
export const SDK_INIT_PATH = `/sdk/init`;
export const SDK_LOAD_PATH = `/sdk/widgets`;
export const SDK_EVENT_PATH = `/sdk/ev`;
export const SDK_DEPRECATED_EVENT_PATH = `/sdk/e`;
export const SDK_LOAD_MORE_PATH = `/sdk/widgets/more_stories`;
export const SDK_CREATE_RECOMMENDATION_PATH = `/sdk/widgets/create_recommendation`;
export const SDK_CREATE_ATOM_REFERRAL_PATH = `/sdk/widgets/create_atom_referral`;
export const SDK_UPDATE_ATOM_REFERRAL_PATH = `/sdk/widgets/update_atom_referral`;
export const SDK_CREATE_REFERRAL_FROM_PERSON_PATH = `/sdk/widgets/create_referral_from_person`;
export const SDK_CREATE_REFERRAL_LEAD = `/sdk/widgets/create_referral_lead`;
export const SDK_CREATE_REVIEW_PATH = `/sdk/widgets/create_review`;
export const SDK_CREATE_LEAD_PATH = `/sdk/widgets/create_lead`;
export const SDK_TEMP_COMPANY_SEARCH = '/sdk/company-search';
export const SDK_TEMP_COMPANY_LOCATION_SEARCH = '/sdk/company-location-search';
export const SDK_TEMP_COMPANY_DETAIL = '/sdk/company-detail';
export const SDK_CREATE_REFERRAL_LEAD_PATH = `/sdk/widgets/create_referral_lead`;
export const SDK_NPS_SUBMIT_FEEDBACK_PATH = `/sdk/widgets/submit_feedback`;

//  --- Stories widget ---
export const GRID_STORIES = 15;
export const COLUMN_STORIES = 15;

// --- Trust Badge widget ---
export const TRUST_BADGE_NUMBER_OF_REVIEWS = 3;

//  --- Data fields ---

//  Stories widget styling
export const STORIES_STYLE = 'data-style';
export const STORIES_BRANDING = 'data-branding';
export const STORIES_STORY_ID = 'data-story-id';

//  Lead form external override
export const LEAD_FORM_FORCE_NJ = 'data-force-nj-lead';
export const LEAD_FORM_NETWORK = 'data-network';

//  Trust badge reviews toggle
export const TRUST_BADGE_SHOW_REVIEWS = 'data-show-reviews';

//  ReviewForm/Recommendation CampaignRequest referrer
export const WIDGET_CAMPAIGN_REQUEST_SLUG = 'data-campaign-request-slug';

// NPS survey click tracker
export const NPS_SURVEY_CLICK_TRACKER = 'data-njclid';

//  Engage position, event types
export const ENGAGE_WIDGET_POSITION = 'data-position';
export const ENGAGE_EVENT_TYPES = 'data-event-types';

//  Goals
export const DATA_GOAL_TYPE = `data-goal-type`;

//  --- Other ---
export const SDK_INIT_REDIRECT_PARAM = `nj-redirect`;
export const SDK_PUBLIC_KEY_PARAM = `__public_key__`;

// Referral and Referal Lead form widget parameters
export const LOAD_REFERRAL_LEAD_FORM_PARAM = `lrlf`;
export const LOAD_ATOM_REFERRAL_PAGE_PARAM = `larp`;
export const REFERRAL_NETWORK_TYPE_PARAMETER = `network_type`;

//  SDK <script> attributes
export const SCRIPT_SESSION_ID_ATTRIBUTE = 'data-sid';
export const SCRIPT_PAGEVIEW_ID_ATTRIBUTE = 'data-pid';
export const SCRIPT_CSRF_TOKEN_ATTRIBUTE = 'data-csrf';

//  setGlobalMetadata()
export const GLOBAL_METADATA_PARAM = '__global_metadata__';

// SDK ACCOUNT/MULTIPLE COMPANIES DATA ATTRIBUTE
export const SDK_DATA_SOURCE = 'data-source';
export const SDK_ACCOUNT_SOURCE = 'account';

// Cache keys
export const STORIES_WIDGET_CACHE_PREFIX = 'stories.widget.sdk';

/** MINIMUM  */
export const MINIMUM_REVIEW_RATING = 4;
